import axios from 'axios'
import { navigate } from 'gatsby'
import React, { Component } from 'react'
import { Field, Form } from 'react-final-form'
import { ReactSVG } from 'react-svg'
import 'url-search-params-polyfill'
import { strapi, trFunction } from 'utils/functions'
var hasher = require('wordpress-hash-node')

// TODO: login returns to previous page

class LostPasswordForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      postedEmail: null,
      error: null,
      loading: false,
    }
  }

  render() {
    const locale = this.props.data.site.siteMetadata.locale
    const translations = this.props.data.translations
    const tr = (code) => trFunction(code, translations, locale)

    const onSubmit = (values) => {
      this.setState({ error: null, loading: true })

      const urlParams = new URLSearchParams(window.location.search)
      const urlToken = urlParams.get('token')

      // check for new passwords match
      if (values.passwordNew !== values.passwordNewConfirm) {
        this.setState({ error: '' })
        setTimeout(() => {
          this.setState({
            postedEmail: values.email,
            error: 'passwordsNotMatch',
            loading: false,
          })
        }, 500)
      } else {
        // get token from strapi user-password-tokens
        let getTokenUrl = strapi.url
        getTokenUrl += `/user-password-tokens?token=${urlToken}`

        axios({
          method: 'get',
          url: getTokenUrl,
          headers: {
            Authorization: `Bearer ${strapi.token}`,
          },
        }).then((response) => {
          if (response.data.length < 1) {
            // no token, tampering, or other issue
            this.setState({ error: 'tokenIssue' })
            setTimeout(() => {
              this.setState({
                postedEmail: values.email,
                error: 'tokenIssue',
                loading: false,
              })
            }, 500)
          } else {
            // got token
            // use token email to get user
            const tokenEntry = response.data[0]
            const tokenEmail = tokenEntry.email
            let getUserUrl = strapi.url
            getUserUrl += `/users?email=${tokenEmail}`
            axios({
              method: 'get',
              url: getUserUrl,
              headers: {
                Authorization: `Bearer ${strapi.token}`,
              },
            }).then((response) => {
              if (response.data.length < 1) {
                // noEmail, but no translation so use token error
                // should not happen: only if token exists but token email + user email no longer match
                this.setState({ error: '' })
                setTimeout(() => {
                  this.setState({
                    postedEmail: values.email,
                    error: 'tokenIssue',
                    loading: false,
                  })
                }, 500)
              } else {
                // got user
                const getUser = response.data[0]
                const newPasswordHashed = hasher.HashPassword(
                  values.passwordNew,
                )

                let updateUserUrl = strapi.url
                updateUserUrl += `/users/${getUser.id}`
                axios({
                  method: 'put',
                  url: updateUserUrl,
                  headers: {
                    Authorization: `Bearer ${strapi.token}`,
                  },
                  data: {
                    password2: newPasswordHashed,
                  },
                }).then((response) => {
                  this.setState({ error: '' })
                  setTimeout(() => {
                    this.setState({ error: 'success', loading: false })
                  }, 500)
                  // if session, update password
                  if (sessionStorage.getItem('loginUser')) {
                    let updateUser = JSON.parse(
                      sessionStorage.getItem('loginUser'),
                    )
                    updateUser.password2 = newPasswordHashed
                    sessionStorage.setItem(
                      'loginUser',
                      JSON.stringify(updateUser),
                    )
                    navigate(`/my-account/change-password?token=${urlToken}`)
                  }
                })
              }
            })
          }
        })
      }
    }

    const loadingClass = this.state.loading ? 'loading' : ''
    return (
      <div className="woocomerce-form">
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <p className="form-row form-row-wide">
                <Field
                  name="passwordNew"
                  component="input"
                  type="password"
                  placeholder={tr('FORM_ITEM_NEW_PASSWORD')}
                  required
                />
              </p>
              <p className="form-row form-row-wide">
                <Field
                  name="passwordNewConfirm"
                  component="input"
                  type="password"
                  placeholder={tr('FORM_ITEM_CONFIRM_NEW_PASSWORD')}
                  required
                />
              </p>

              <div className={`submit-wrap full ${loadingClass}`}>
                <span className="loader">
                  <ReactSVG src="/images/loader.svg" />
                </span>
                <input
                  className="woocommerce-Button button"
                  type="submit"
                  value={tr('FORM_ITEM_RESET_PASSWORD')}
                  disabled={this.state.loading}
                />
              </div>
            </form>
          )}
        />
        <ul className="error-holder">
          <li
            className={this.state.error === 'passwordsNotMatch' ? 'show' : ''}
          >
            <strong>{tr('FORM_ITEM_ERROR')}</strong>
            {tr('FORM_CHANGE_PASSWORD_ERROR_PASSWORDS_NOT_MATCH')}
          </li>
          <li className={this.state.error === 'tokenIssue' ? 'show' : ''}>
            <strong>{tr('FORM_ITEM_ERROR')}</strong>
            {tr('FORM_CHANGE_PASSWORD_ERROR_TOKEN_ISSUE')}
          </li>
          <li className={this.state.error === 'success' ? 'show' : ''}>
            {tr('FORM_CHANGE_PASSWORD_SUCCESS')}
          </li>
        </ul>
      </div>
    )
  }
}

export default LostPasswordForm
