import React from 'react'
import ChangePasswordForm from 'components/forms/changePassword'
import Layout from 'components/layout'
import Title from 'components/title'
import { trFunction } from 'utils/functions'
import { graphql } from 'gatsby'

const ChangePassword = ({ data }) => {
  const locale = data.site.siteMetadata.locale
  const translations = data.translations
  const tr = (code) => trFunction(code, translations, locale)
  const page = data.thisPage

  return (
    <Layout>
      <div id="content" className="page-template-page-account">
        <Title title={page.title} />
        <section className="page-content wrap">
          <div
            dangerouslySetInnerHTML={{
              __html: page.body.childMarkdownRemark.html,
            }}
          />
          {typeof sessionStorage !== 'undefined' && (
            <ChangePasswordForm tr={tr} data={data} />
          )}
        </section>
      </div>
    </Layout>
  )
}

export default ChangePassword

export const changePasswordQuery = graphql`
  query changePasswordQuery {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "change-password" }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
